<template>
    <div class="view-home for-members-bg">
        <div class="for-puple-page ">
            <div class="container pb-lg-5 pb-5">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb pl-0">
                        <li class="breadcrumb-item"><a href="#">{{ $t('breadcrumb.main-page') }}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.for-members') }}</li>
                    </ol>
                </nav>
                <div class="page-title  text-center text-lg-left mb-lg-5">
                    {{ $t('for-members.title') }}
                </div>
            </div>
        </div>
        <div class="for-puple-page ">
            <div class="container">
                <div class="row mb-5">
                    <div class="col-lg-12">
                        <div class="card card-secondary  mt-lg-5">
                            <div class="card-title">
                                {{ $t('for-members.card-1-title') }}
                            </div>
                            <div class="card-body ">
                                <div class="row">
                                    <div class="col-xl-8 col-lg-8 col-md order-2 order-lg-1">
                                        <ul class="list-group check">
                                            <li class="list-group-item">
                                                {{ $t('for-members.card-1-item-1') }}
                                            </li>
                                            <li class="list-group-item">
                                                {{ $t('for-members.card-1-item-2') }}
                                            </li>
                                            <li class="list-group-item">
                                                {{ $t('for-members.card-1-item-3') }}
                                            </li>
                                            <li class="list-group-item">
                                                {{ $t('for-members.card-1-item-4') }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 text-right order-1 order-lg-2 mb-4 mt-3 mt-lg-0">
                                        <div class="rounded-circle mx-auto rounded-circle-first">
                                            <img src="/images/svg-1.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-lg-12">
                        <div class="card card-default">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-4 text-center">
                                        <div class="d-flex flex-column mb-3 position-relative">
                                            <span class="title mb-5 d-inline-block">
                                                www.birgeoqy.kz
                                            </span>
                                            <img src="/images/cursor-icon.svg" class="cursor-icon">
                                            <span>
                                                <img src="/images/qr-image.svg" alt="">
                                            </span>
                                        </div>

                                    </div>
                                    <div class="col-lg-8">
                                        <p>{{ $t('for-members.card-2-desc-1') }}</p>
                                        <p>{{ $t('for-members.card-2-desc-2') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-lg-12">
                        <div class="card card-figure card-default  corner mt-lg-5 position-relative">
                            <div class="rounded-circle mx-auto">
                                <img src="/images/svg-2.svg" alt="" class="w-100">
                            </div>
                            <div class="content_first">
                                 <p>{{ $t('for-members.card-3-desc-1') }}</p>
                            </div>
                            <div class="content_second">
                                {{ $t('for-members.card-3-desc-2') }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-5 pb-5">
                    <div class="col-lg-12 mb-5">
                        <div class="card card-secondary  mt-lg-5">
                            <div class="card-title">
                                {{ $t('for-members.card-4-title') }}
                            </div>
                            <div class="card-body ">
                                <div class="row ">
                                    <div class="col-xl-8 col-lg-8 col-md order-2 order-lg-1">
                                        <ul class="list-group check">
                                            <li class="list-group-item">
                                                {{ $t('for-members.card-4-item-1') }}
                                            </li>
                                            <li class="list-group-item">
                                                {{ $t('for-members.card-4-item-2') }}
                                            </li>
                                            <li class="list-group-item">
                                                {{ $t('for-members.card-4-item-3') }}
                                            </li>
                                            <li class="list-group-item">
                                                {{ $t('for-members.card-4-item-4') }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 text-right order-1 order-lg-2 mb-5 mt-3 mt-lg-0">
                                        <div class="rounded-circle mx-auto">
                                            <img src="/images/svg-3.svg" alt="">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
}
</script>

<style scoped>
    .cursor-icon {
        width: 25px;
        height: 35px;
        position: absolute;
        right: 0;
        top: 36px;
    }
      @media screen and (min-width: 992px) and (max-width: 1200px) {
          .card-default .title {
              font-size: 31px;
          }
      }
</style>